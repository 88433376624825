import { useContext } from 'react'
import { useParams } from 'react-router-dom'
import { useQuery, gql } from '@apollo/client'
import dateToJpdate from '../helper/dateToJpdate'
import style from '@emotion/styled'
import ThemeContext from '../ThemeContext'
import MyContext from '../TabRef'
import './post.css'
import Stack from '@mui/material/Stack'
import Chip from '@mui/material/Chip'
import RestoreIcon from '@mui/icons-material/Restore'
import CircularProgress from '@mui/material/CircularProgress'

const TitleWrap = style.div`
    display: flex;
    justify-content : center;
    align-items : center;
    height: 360px;
    width: 100%;
    overflow: hidden;
    position: relative;
`

const DarkBackImg = style.img`
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translateX(-50%) translateY(-50%);
    min-width: 100%;
    min-height: 100%;
    z-index: -1;
    filter: brightness(.5);
`
const WhiteBox = style.div`
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translateX(-50%) translateY(-50%);
    min-width: 90%;
    min-height: 72%;
    z-index: 0;
    background-color: #fff;
    opacity: 0.4;
`

const GET_POST = gql`
    query GetPost($id: ID!) {
        post(id: $id) {
            id
            title
            date
            tags {
                nodes {
                    name
                    slug
                    id
                }
            }
            featuredImage {
                node {
                    mediaItemUrl
                    srcSet
                }
            }
            content(format: RENDERED)
        }
    }
`
type Tag = {
    name: string
    slug?: string
    id: string
}

const BlogPost = () => {
    const { mode } = useContext(ThemeContext)
    const { ref } = useContext(MyContext)

    const { page_id } = useParams()
    const { loading, error, data } = useQuery(GET_POST, {
        variables: { id: page_id },
    })
    if (loading) {
        return (
            <div
                style={{
                    display: 'flex',
                    width: '100%',
                    height: '92vh',
                    justifyContent: 'center',
                    alignItems: 'center',
                    verticalAlign: 'middle',
                }}
            >
                <CircularProgress color="inherit" /> <span>Loading...</span>
            </div>
        )
    }
    if (error) return <p>記事の読み込みに失敗しました</p>
    if (!data.post) {
        return <p>記事が有りません</p>
    }

    return (
        <div>
            <TitleWrap>
                {mode === 'light' && <WhiteBox />}
                <DarkBackImg alt={data?.post.title} srcSet={data?.post.featuredImage.node.srcSet} />
                <div style={{ zIndex: '10', textAlign: 'center' }}>
                    <h1>{data?.post.title}</h1>
                    <p>
                        <RestoreIcon fontSize={'small'} style={{ marginRight: '6px', verticalAlign: 'text-bottom' }} />
                        {dateToJpdate(data?.post.date)}
                    </p>

                    <Stack direction="row" spacing={0.5} justifyContent="center">
                        {data?.post.tags.nodes.map((node: Tag) => (
                            <Chip label={node.name} key={node.id} color="primary" style={{ letterSpacing: '1px' }} />
                        ))}
                    </Stack>
                </div>
            </TitleWrap>

            <div dangerouslySetInnerHTML={{ __html: data?.post.content }} ref={ref} />
        </div>
    )
}

export default BlogPost
