// import { useRef, useEffect } from 'react'
import { useQuery, gql } from '@apollo/client'
import Grid from '@mui/material/Unstable_Grid2'
import Card from '../components/Card'
import CircularProgress from '@mui/material/CircularProgress'

const GET_POSTS = gql`
    query GetPostsEdges {
        posts {
            edges {
                node {
                    date
                    title
                    slug
                    uri
                    id
                    link
                    excerpt
                    isContentNode
                    tags {
                        nodes {
                            name
                            link
                        }
                    }
                    featuredImage {
                        node {
                            mediaItemUrl
                            srcSet
                        }
                    }
                }
            }
        }
    }
`

export const List = () => {
    const { loading, error, data } = useQuery(GET_POSTS)
    if (loading) {
        return (
            <div
                style={{
                    display: 'flex',
                    width: '100%',
                    height: '92vh',
                    justifyContent: 'center',
                    alignItems: 'center',
                    verticalAlign: 'middle',
                }}
            >
                <CircularProgress color="inherit" /> <span>Loading...</span>
            </div>
        )
    }
    if (error) return <p>Error : {error.message}</p>

    return (
        <Grid container spacing={{ xs: 2, md: 3 }} columns={{ xs: 2, sm: 8, md: 9, lg: 12 }}>
            {data?.posts.edges.map(({ node }: any) => {
                return (
                    <Grid
                        xs={2}
                        sm={4}
                        md={3}
                        lg={3}
                        key={node.id}
                        style={{ alignItems: 'stretch', display: 'inherit' }}
                    >
                        <Card node={node} />
                    </Grid>
                )
            })}
        </Grid>
    )
}

export default List
