function formatDate(inputDate) {
    const options = {
        year: 'numeric',
        month: 'long',
        day: 'numeric',
        hour: 'numeric',
        minute: 'numeric',
        second: 'numeric',
    }

    return new Date(inputDate).toLocaleDateString('ja-JP', options)
}

export default formatDate
