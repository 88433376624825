import Prism from 'prismjs'

// Prism.jsのスタイルシートをインポート
import 'prismjs/themes/prism-okaidia.min.css'

//利用する言語をインポート
import 'prismjs/components/prism-python.min.js'
import 'prismjs/components/prism-javascript.min'
import 'prismjs/components/prism-bash.min.js'
import 'prismjs/components/prism-scss.min.js'
import 'prismjs/components/prism-css.min.js'
// import 'prismjs/components/prism-core.min.js'

//プラグインをインポート
import 'prismjs/plugins/line-numbers/prism-line-numbers.min.css'
import 'prismjs/plugins/line-numbers/prism-line-numbers.min.js'

export function setupPrism() {
    Prism.highlightAll()
}
