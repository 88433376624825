import { useState, useEffect, useRef } from 'react'
import { createTheme, ThemeProvider } from '@mui/material/styles'
import { blueGrey, grey, indigo } from '@mui/material/colors'
import { CssBaseline, Container, useMediaQuery } from '@mui/material'
import { PaletteOptions } from '@mui/material/styles/createPalette'

import Header from './components/Header'
import routes from './pages/Routes'
import { RouterProvider } from 'react-router-dom'
import ThemeContext from './ThemeContext'
import ReactGA from 'react-ga4'
import DynamicVideo from './components/DynamicVideo'

import MyContext from './TabRef'
import { setupPrism } from './prismSetup'

const VIDEO_NAMES = ['default', 'autumn', 'winter', 'spring', 'summer']

export default function MaterialMode() {
    const videoRef = useRef<HTMLVideoElement>(null)
    const myref = useRef<HTMLDivElement | null>(null)
    const [videoName] = useState(() => {
        const index = Math.floor(Math.random() * VIDEO_NAMES.length)
        return VIDEO_NAMES[index]
    })

    const siteLocation = routes.state.location

    useEffect(() => {
        ReactGA.initialize('G-J424S1GX51')
        ReactGA.send({
            hitType: 'pageview',
            page: siteLocation.pathname + siteLocation.search,
        })
    }, [siteLocation])

    useEffect(() => {
        videoRef.current?.play()
    }, [videoRef])

    useEffect(() => {
        setTimeout(() => {
            const $tabsContenas = myref.current?.querySelectorAll('.tabs-container')
            if (!$tabsContenas) {
                return
            }

            $tabsContenas?.forEach(function ($tabsContena) {
                const $tabs = $tabsContena.querySelectorAll('.tab-title')
                const $panels = $tabsContena.querySelectorAll('.single-tab')
                $tabs[0].classList.add('is-active')
                $panels[0].classList.add('is-show')
                for (let i = 0; i < $tabs.length; i++) {
                    $tabs[i].addEventListener('click', function () {
                        for (let i = 0; i < $tabs.length; i++) {
                            $tabs[i].classList.remove('is-active')
                            $panels[i].classList.remove('is-show')
                        }
                        tabSwitch.call($tabs[i], $panels[i])
                    })
                }
                function tabSwitch(this: any, $panels: any) {
                    this.classList.add('is-active')
                    $panels.classList.add('is-show')
                }
            })
            setupPrism()
        }, 1000)
    }, [])

    const initMode = useMediaQuery('(prefers-color-scheme: dark)') ? 'dark' : 'light'

    const [mode, setMode] = useState(() => {
        const storageMode = localStorage.getItem('mode')
        return storageMode ? storageMode : initMode
    })

    useEffect(() => {
        localStorage.setItem('mode', mode)
    }, [mode])

    const themeConfig = {
        mode,
        toggleMode: () => {
            setMode((prev) => (prev === 'light' ? 'dark' : 'light'))
        },
    }

    const refConfig = {
        ref: myref,
    }

    const theme = createTheme({
        palette: {
            mode,
            ...(mode === 'light'
                ? {
                      primary: {
                          main: blueGrey[800],
                          contrastText: '#fff',
                      },
                      background: {
                          //   default: grey[300],
                          paper: grey[100],
                      },
                  }
                : {
                      primary: {
                          main: indigo[900],
                          contrastText: '#fff',
                      },
                      background: {
                          default: grey[900],
                          paper: grey[900],
                      },
                  }),
        } as PaletteOptions,
    })

    return (
        <ThemeContext.Provider value={themeConfig}>
            <ThemeProvider theme={theme}>
                <MyContext.Provider value={refConfig}>
                    <CssBaseline />
                    <Header />
                    <DynamicVideo name={videoName} videoRef={videoRef} />
                    {/* <source src={video_mp4} type="video/mp4" /> */}
                    <main style={{ paddingTop: '100px', paddingBottom: '50px' }} data-mode={mode}>
                        <Container maxWidth="xl">
                            <RouterProvider router={routes} />
                        </Container>
                    </main>
                </MyContext.Provider>
            </ThemeProvider>
        </ThemeContext.Provider>
    )
}
