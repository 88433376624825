import { createRoutesFromElements, createBrowserRouter, Route } from 'react-router-dom'
import List from './Lists'
import BlogPost from './Post'

const mode =
    process.env.NODE_ENV === 'development' ? process.env.REACT_APP_LOCAL_URL : process.env.REACT_APP_PRODUCT_URL

// console.log(mode)
const routes = createBrowserRouter(
    createRoutesFromElements(
        <>
            {/* <Route path="/"> */}
            <Route path="/" element={<List />} />
            <Route path="/post/:page_id" element={<BlogPost />} />
            {/* </Route> */}
        </>
    ),
    { basename: mode }
)

export default routes
