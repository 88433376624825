import * as React from 'react'
import Card from '@mui/material/Card'
import CardContent from '@mui/material/CardContent'
import CardMedia from '@mui/material/CardMedia'
import Typography from '@mui/material/Typography'
import { CardActionArea } from '@mui/material'
import RestoreIcon from '@mui/icons-material/Restore'
import dateToJpdate from '../helper/dateToJpdate'

export default function ActionAreaCard({ node }: any) {
    const replacePtag = (excerpt: any) => {
        return excerpt.replace(/<p>(.*?)<\/p>/g, '$1')
    }

    return (
        <Card sx={{ maxWidth: 600 }}>
            <CardActionArea href={`post/${node.id}`} style={{ height: '100%' }}>
                <CardMedia
                    component="img"
                    height="200"
                    image={node.featuredImage.node.mediaItemUrl}
                    alt={node.title}
                    srcSet={node.featuredImage.node.srcSet}
                />
                <CardContent>
                    <div style={{ marginBottom: '0.5em', opacity: '0.8' }}>
                        <RestoreIcon fontSize={'small'} style={{ marginRight: '6px', verticalAlign: 'text-bottom' }} />
                        <span>{dateToJpdate(node.date)}</span>
                    </div>
                    <Typography gutterBottom variant="h6" component="h2" noWrap>
                        {node.title}
                    </Typography>
                    <Typography variant="body1" color="text.secondary">
                        {replacePtag(node.excerpt)}
                    </Typography>
                </CardContent>
            </CardActionArea>
        </Card>
    )
}
