import { useContext } from 'react'
import AppBar from '@mui/material/AppBar'
// import Box from '@mui/material/Box'
import Toolbar from '@mui/material/Toolbar'
import Typography from '@mui/material/Typography'
import Button from '@mui/material/Button'
import style from '@emotion/styled'

import ThemeContext from '../ThemeContext'

const LogoStyle = style.a`
    color: #FFF;
    text-decoration: none;
`
const Header = () => {
    const { mode, toggleMode } = useContext(ThemeContext)
    return (
        <AppBar position="fixed" color="primary" enableColorOnDark>
            <Toolbar>
                <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
                    <LogoStyle href="/">
                        <svg
                            id="a"
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 131.68 40.03"
                            style={{ maxWidth: '120px', paddingTop: '0.5em' }}
                        >
                            <path
                                d="m20,0C8.95,0,0,8.95,0,20c0,4.03,1.2,7.76,3.22,10.88,1.17-2.73,2.51-5.81,3.1-6.93,0,0,3.39-8.12,12.61-12.25,8.34-3.68,15.73-4,15.73-4,0,0-12.69,4.68-17.69,9.34-3.78,3.51-.78,4.51,2.1,4.73,1.22.1,1.34,1.39.34,2.1-2.34,1.66-4.17,2.1-7.22,2.66-2.95.56-5.64,2.63-8.15,5.54,3.66,4.83,9.44,7.95,15.98,7.95,11.05,0,20-8.95,20-20S31.05,0,20,0Z"
                                style={{ fill: '#fff', strokeWidth: '0px' }}
                            />
                            <path
                                d="m50.88,9.17h10.8v2.11h-8.64v6.75h8.64v2.11h-8.64v10.58h-2.15V9.17Z"
                                style={{ fill: '#fff', strokeWidth: '0px' }}
                            />
                            <path d="m67.64,8.63h2.05v22.09h-2.05V8.63Z" style={{ fill: '#fff', strokeWidth: '0px' }} />
                            <path
                                d="m92.89,14.78v15.94h-2.02v-2.74c-.86,1.04-1.82,1.83-2.89,2.36s-2.24.79-3.51.79c-2.26,0-4.18-.82-5.78-2.45s-2.4-3.63-2.4-5.97.81-4.26,2.42-5.89,3.55-2.45,5.82-2.45c1.31,0,2.49.28,3.55.83s1.99,1.39,2.79,2.5v-2.93h2.02Zm-8.2,1.57c-1.14,0-2.19.28-3.16.84-.97.56-1.73,1.35-2.3,2.36-.57,1.01-.86,2.09-.86,3.22s.29,2.19.86,3.22c.58,1.02,1.35,1.82,2.32,2.39.97.57,2.01.86,3.12.86s2.19-.28,3.19-.85c1-.57,1.78-1.33,2.32-2.3.54-.97.81-2.05.81-3.26,0-1.84-.61-3.38-1.82-4.62s-2.71-1.86-4.48-1.86Z"
                                style={{ fill: '#fff', strokeWidth: '0px' }}
                            />
                            <path
                                d="m100.47,14.78h2.05v2.93c.81-1.11,1.74-1.95,2.79-2.5s2.23-.83,3.53-.83c2.26,0,4.2.82,5.81,2.45,1.61,1.63,2.41,3.59,2.41,5.89s-.8,4.33-2.39,5.97c-1.6,1.64-3.52,2.45-5.77,2.45-1.27,0-2.43-.26-3.5-.79s-2.02-1.31-2.88-2.36v8.57h-2.05V14.78Zm8.2,1.57c-1.77,0-3.27.62-4.48,1.86s-1.82,2.78-1.82,4.62c0,1.21.27,2.3.81,3.26.54.97,1.32,1.73,2.32,2.3s2.07.85,3.19.85,2.14-.29,3.11-.86,1.74-1.37,2.32-2.39c.57-1.02.86-2.1.86-3.22s-.29-2.2-.85-3.22c-.57-1.01-1.34-1.8-2.3-2.36-.96-.56-2.02-.84-3.16-.84Z"
                                style={{ fill: '#fff', strokeWidth: '0px' }}
                            />
                            <path
                                d="m131.67,16.53l-1.32,1.36c-1.1-1.06-2.17-1.6-3.22-1.6-.67,0-1.24.22-1.71.66-.48.44-.71.95-.71,1.54,0,.52.2,1.01.59,1.48.39.48,1.21,1.04,2.47,1.68,1.53.79,2.56,1.55,3.11,2.29.54.74.81,1.58.81,2.5,0,1.31-.46,2.42-1.38,3.33s-2.07,1.36-3.45,1.36c-.92,0-1.8-.2-2.63-.6-.84-.4-1.53-.95-2.08-1.66l1.29-1.46c1.05,1.18,2.16,1.77,3.33,1.77.82,0,1.52-.26,2.1-.79.58-.53.87-1.15.87-1.86,0-.59-.19-1.11-.57-1.57-.38-.45-1.24-1.02-2.58-1.7-1.44-.74-2.42-1.47-2.94-2.2s-.78-1.55-.78-2.48c0-1.21.41-2.22,1.24-3.02s1.87-1.2,3.13-1.2c1.47,0,2.94.72,4.43,2.15Z"
                                style={{ fill: '#fff', strokeWidth: '0px' }}
                            />
                        </svg>
                    </LogoStyle>
                </Typography>
                <Button color="inherit" onClick={toggleMode}>
                    {mode}
                </Button>
            </Toolbar>
        </AppBar>
    )
}

export default Header
