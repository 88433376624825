import React from 'react'
import ReactDOM from 'react-dom/client'
import './index.css'
import reportWebVitals from './reportWebVitals'

import { ApolloClient, InMemoryCache, createHttpLink, ApolloProvider } from '@apollo/client'
import { setContext } from '@apollo/client/link/context'

import MaterialMode from './MaterialMode'
const username = process.env.REACT_APP_API_USER
const password = process.env.REACT_APP_API_PASS

const httpLink = createHttpLink({
    uri: process.env.REACT_APP_END_POINT, // GraphQLエンドポイントのURLを設定
})

const authLink = setContext((_, { headers }) => {
    // ベーシック認証のヘッダー情報を生成
    const basicAuth = btoa(`${username}:${password}`)

    // リクエストヘッダーにベーシック認証情報を追加
    return {
        headers: {
            ...headers,
            authorization: `Basic ${basicAuth}`,
        },
    }
})

const client = new ApolloClient({
    link: authLink.concat(httpLink), // ベーシック認証用のリンクを追加
    cache: new InMemoryCache(),
})

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement)
root.render(
    <React.StrictMode>
        <ApolloProvider client={client}>
            <MaterialMode />
        </ApolloProvider>
    </React.StrictMode>
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
