import React, { useState, useEffect } from 'react'
// import video_mp4 from '../movies/default.mp4'

const DynamicVideo = ({ name, videoRef }: any) => {
    const [video, setVideo] = useState()

    useEffect(() => {
        import(`../movies/${name}.mp4`)
            .then((module) => {
                setVideo(module.default)
            })
            .catch((err) => {
                console.log('バックムービーの読込に失敗しました', err.message)
            })
    })

    return (
        <video
            loop
            muted
            src={video}
            ref={videoRef}
            style={{
                position: 'fixed',
                top: '50%',
                left: '50%',
                transform: 'translateX(-50%) translateY(-50%)',
                minWidth: '100%',
                minHeight: '100%',
                width: '100%',
                objectFit: 'cover',
                zIndex: '-1',
                opacity: '0.3',
            }}
        />
    )
}

export default DynamicVideo
